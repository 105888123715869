import React from 'react';

const APIDoc = () => {
  return (
    <div>
      <iframe
        src={`${process.env.REACT_APP_USERMODULE_BASEURL}/proxy/apidoc`}
        title="API Documentation"
        style={{ width: '100%', height: '600px', border: 'none' }}
      >
        Your browser does not support iframes. Click <a href="https://broadbased-communications-limite.gitbook.io/bbc-sms-portal">here</a> to view the API documentation.
      </iframe>
    </div>
  );
};

export default APIDoc;
