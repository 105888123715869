import React, { useEffect, useState } from "react";
import { paymentRequests } from "../../libs/dashboard";
import { loggedInUsers } from "../../store/store";
import DataTable from "react-data-table-component";
import { formatNumber } from "../../libs/helper";
import { formatNumberAsCurrency } from "../../libs/decrypt";

import { Button, DatePicker, Input, Select, Space } from "antd";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const { RangePicker } = DatePicker;
var CryptoJS = require("crypto-js");
let Fetch_values;

const columns = [
  {
    name: "Trans Ref",
    selector: (row) => row.transactionReference,
    // width: "150px",
    cell: (row) => (
      <div
        style={{
          wordBreak: "break-word",
          whiteSpace: "normal",
          textAlign: "left",
          padding: "3px",
        }}
      >
        {row.transactionReference}
      </div>
    ),
  },
  {
    name: "Pay Ref",
    selector: (row) => row.paymentReference,
    // width: "150px",
    cell: (row) => (
      <div
        style={{
          wordBreak: "break-word",
          whiteSpace: "normal",
          textAlign: "left",
          padding: "3px",
        }}
      >
        {row.paymentReference}
      </div>
    ),
  },
  {
    name: "Method",
    selector: (row) => row.paymentMethod,
    width: "100px",
    sortable: true,
  },
  {
    name: "Status",
    selector: (row) => row.status,
    // width: "150px",
    sortable: true,
  },
  {
    name: "Amount",
    selector: (row) => formatNumberAsCurrency(row.amount),
    width: "95px",
  },
  {
    name: "Fees Added",
    selector: (row) => formatNumberAsCurrency(row.feesAdded),
    width: "90px",
  },
  {
    name: "Total",
    selector: (row) => formatNumberAsCurrency(row.totalAmountPaid),
    width: "100px",
  },
  {
    name: "Created At",
    selector: (row) => row.createdAt,
    // width: "150px",
  },
];

const PaymentRequest = () => {
  const { userdata } = loggedInUsers((state) => state.auth);
  // Decrypt
  var getUser = CryptoJS.AES.decrypt(
    userdata,
    process.env.REACT_APP_SOCKET_ENCRYPTION_KEY
  );
  var userD = JSON.parse(getUser.toString(CryptoJS.enc.Utf8));

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [filteredItem, setFilteredItem] = useState();
  const [filterDate, setFilterDate] = useState();
  const [searchedItem, setSearchedItem] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData((Fetch_values = { page: 1, perPage }));
  }, [perPage]);

  const onDateChange = (value, dateString) => {
    if (value) {
      setFilterDate(dateString);
      const SD = dateString[0];
      const ED = dateString[1];
      fetchData((Fetch_values = { page: 1, perPage, SD, ED }));
    } else {
      fetchData((Fetch_values = { page: 1, perPage }));
    }
  };

  const fetchData = async (Fetch_values) => {
    setLoading(true);
    Fetch_values = Object.assign(
      Fetch_values,
      { email: userD?.email },
      { bearerToken: userD?.bearerToken }
    );
    const getRec = await paymentRequests(Fetch_values);
    // console.log(getRec);
    if (getRec) {
      setIsLoaded(true);
      setLoading(false);
      setItems(getRec.data);
      setTotalRows(getRec.headers["x-pagination-totalcount"]);
    } else {
      setIsLoaded(true);
      setError(error);
    }
  };

  const handlePageChange = (page) => {
    fetchData(
      (Fetch_values = {
        page,
        perPage,
        filteredItem,
        searchedItem,
        SD: filterDate?.[0],
        ED: filterDate?.[1],
      })
    );
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const onFilterChange = (value) => {
    setFilteredItem(value);
  };

  const handleFilter = (event) => {
    if (event.target.value) {
      setSearchedItem(event.target.value);
    } else {
      setClear();
    }
  };

  const handleSubmitFilter = (e) => {
    fetchData(
      (Fetch_values = { page: 1, perPage, filteredItem, searchedItem })
    );
  };

  const handleKeypress = (e) => {
    if (e.keyCode === 13) {
      if (!e.target.value) {
        setClear();
        toast.error("Empty Search Field");
      } else {
        handleSubmitFilter();
      }
    }
  };

  const setClear = () => {
    setSearchedItem();
    setFilteredItem();
    fetchData((Fetch_values = { page: 1, perPage }));
  };

  const dataRowStyles = {
    color: loading ? "gray" : "black", // Apply conditional color
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Loading...</div>;
  } else {
    return (
      <div className="App">
        <h1 className="text-xl font-semibold">Payment Request History</h1>
        <p className="text-xs font-semibold mb-2">
          <span className="text-xl font-semibold">
            {formatNumber(totalRows)}
          </span>{" "}
          Records Found
        </p>
        <div className="text-end pb-2 gap-2">
          <Space direction="vertical" size={14} className="w-52"></Space>
          <RangePicker onChange={onDateChange} /> &nbsp;
          {/* <input type="text" placeholder="Filter" /> */}
          <Select
            style={{
              width: 125,
            }}
            value={filteredItem}
            onChange={onFilterChange}
            options={[
              {
                value: "transactionReference",
                label: "Trans Ref",
              },
              {
                value: "paymentReference",
                label: "Pay Ref",
              },
              {
                value: "paymentMethod",
                label: "Pay Method",
              },
              {
                value: "status",
                label: "Status",
              },
            ]}
            allowClear="true"
            placeholder="Filter By"
          />
          <Input
            // value={searchedItem}
            className="w-48 font-poppins ml-1"
            placeholder="Search"
            // allowClear="true"
            // onClear={setClear}
            onChange={handleFilter}
            onKeyDown={handleKeypress}
          />
          <Button
            type="primary"
            onClick={handleSubmitFilter}
            disabled={searchedItem ? false : true}
            className={
              "rounded-1xbg-indigo-500 text-decoration-none fs-8 hover:scale-105 duration-300 text-white ml-2"
            }
          >
            ok
          </Button>
        </div>
        <DataTable
          columns={columns}
          data={items}
          fixedHeader
          pagination
          highlightOnHover="true"
          paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 50, 100]}
          paginationServer
          paginationTotalRows={totalRows}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handlePerRowsChange}
          customStyles={{
            rows: {
              style: dataRowStyles,
            },
          }}
        />
        <ToastContainer autoClose={3000} theme="colored" newestOnTop={true} />
      </div>
    );
  }
};

export default PaymentRequest;
