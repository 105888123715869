import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { getAllNetworks, smsTransactionDetail } from "../../libs/dashboard";
import { loggedInUsers } from "../../store/store";
import { formatNumber } from "../../libs/helper";
import { Button, DatePicker, Input, Select, Space } from "antd";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const { RangePicker } = DatePicker;

// const columns = [
//   {
//     title: "Name",
//     dataIndex: "name",
//     sorter: true,
//     render: (name) => `${name.first} ${name.last}`,
//     width: "20%",
//   },
//   {
//     title: "Gender",
//     dataIndex: "gender",
//     filters: [
//       {
//         text: "Male",
//         value: "male",
//       },
//       {
//         text: "Female",
//         value: "female",
//       },
//     ],
//     width: "20%",
//   },
//   {
//     title: "Email",
//     dataIndex: "email",
//   },
//   {
//     title: "Age",
//     dataIndex: "age",
//   },
//   {
//     title: "Status",
//     dataIndex: "status",
//   },
// ];

const TooltipCell = ({ value }) => (
  <div
    title={value}
    style={{
      width: "400px",
    }}
  >
    <span>{value.length > 20 ? `${value.substring(0, 20)}...` : value}</span>
  </div>
);

const columns = [
  {
    name: "Id",
    selector: (row) => row.batchId,
    // width: "250px",
    cell: (row, index, column, id) => {
      return ( <div
        style={{
          wordBreak: "break-word",
          whiteSpace: "normal",
          maxWidth: "90px",
          minWidth: "90px",
          textAlign: "left",
          padding: "3px"
        }}>
          {row.batchId}
      </div>);
    }
  },
  {
    name: "Created At",
    selector: (row) => row.createdAt,
    // width: "150px",
    cell: (row, index, column, id) => {
      return (  <div
        style={{
          wordBreak: "break-word",
          whiteSpace: "normal",
          maxWidth: "90px",
          minWidth: "90px",
          textAlign: "left",
          padding: "3px"
        }}>
          {row.createdAt}
      </div>);
    }
  },
  {
    name: "Status",
    selector: (row) => row.status,
    cell: (row, index, column, id) => {
      return (  <div
        style={{
          wordBreak: "break-word",
          whiteSpace: "normal",
          // maxWidth: "70px",
          // minWidth: "70px",
          textAlign: "left",
          padding: "3px"
        }}>
          {row.status}
      </div>);
    },
    // width: "250px",
    sortable: true,
  },
  {
    name: "Message",
    selector: (row) => row.message,
    width: "250px",
    cell: (row, index, column, id) => {
      return (
        <div
          style={{
            wordBreak: "break-word",
            whiteSpace: "normal",
            textAlign: "left",
            padding: "10px 0px"
          }}
        >
          {row.message}
        </div>
      );
    },
  },
  {
    name: "Network",
    selector: (row) => row.network,
    // width: "120px",
    sortable: true,
  },
  {
    name: "Recipient",
    selector: (row) => row.recipient,
    // width: "110px",
  },
  {
    name: "Sender Id",
    selector: (row) => row.senderId,
    // width: "110px",
  },
  {
    name: "Sms Route",
    selector: (row) => row.smsRoute,
    // width: "110px",
  },
];

var CryptoJS = require("crypto-js");
let Fetch_values;

const SMShistory = () => {
  const { userdata } = loggedInUsers((state) => state.auth);
  // Decrypt
  var getUser = CryptoJS.AES.decrypt(
    userdata,
    process.env.REACT_APP_SOCKET_ENCRYPTION_KEY
  );
  var userD = JSON.parse(getUser.toString(CryptoJS.enc.Utf8));

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [filteredItem, setFilteredItem] = useState();
  const [filterDate, setFilterDate] = useState();
  const [searchedItem, setSearchedItem] = useState();
  const [allNEtwork, setAllNEwork] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData((Fetch_values = { page: 1, perPage }));
    getAllNetwork();
  }, [perPage]);

  const onDateChange = (value, dateString) => {
    // console.log('Selected Time: ', value);
    if (value) {
      // console.log("Formatted Selected Time: ", dateString);
      setFilterDate(dateString);
      const SD = dateString[0];
      const ED = dateString[1];
      fetchData((Fetch_values = { page: 1, perPage, SD, ED }));
    } else {
      fetchData((Fetch_values = { page: 1, perPage }));
    }
  };

  const fetchData = async (Fetch_values) => {
    setLoading(true);

    Fetch_values = Object.assign(
      Fetch_values,
      { email: userD?.email },
      { bearerToken: userD?.bearerToken }
    );
    const getSID = await smsTransactionDetail(Fetch_values);
    if (getSID) {
      setIsLoaded(true);
      setLoading(false);
      setItems(getSID.data);
      setTotalRows(getSID.headers["x-pagination-totalcount"]);
      // console.log("Fetching", loading);
    } else {
      setIsLoaded(true);
      setError(error);
    }
  };

  const getAllNetwork = async () => {
    const getNetW = await getAllNetworks({
      email: userD?.email,
      bearerToken: userD?.bearerToken,
    });
    const result = getNetW.data.data;
    // console.log(resp);

    const func = Object.entries(result).map(([label, value]) => ({
      label,
      value,
    }));
    setAllNEwork(func);
  };

  const handlePageChange = (page) => {
    // console.log("pageChange", page);
    fetchData(
      (Fetch_values = {
        page,
        perPage,
        filteredItem,
        searchedItem,
        SD: filterDate?.[0],
        ED: filterDate?.[1],
      })
    );
  };

  const onFilterChange = (value) => {
    setFilteredItem(value);
  };
  const onFilterNet = (value) => {
    setSearchedItem(value);
    if (value) {
      fetchData(
        (Fetch_values = { page: 1, perPage, filteredItem, searchedItem: value })
      );
      // console.log("set", value);
    } else setClear();
  };
  const handleFilter = (event) => {
    if (event.target.value) {
      setSearchedItem(event.target.value);
    } else {
      setClear();
    }
  };
  const handleSubmitFilter = (e) => {
    fetchData(
      (Fetch_values = { page: 1, perPage, filteredItem, searchedItem })
    );
  };
  const handleKeypress = (e) => {
    if (e.keyCode === 13) {
      if (!e.target.value) {
        setClear();
        toast.error("Empty Search Field");
      } else {
        handleSubmitFilter();
      }
    }
  };

  const setClear = () => {
    setSearchedItem();
    setFilteredItem();
    fetchData((Fetch_values = { page: 1, perPage }));
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
    // console.log("Log PerPage", perPage);
  };

  const dataRowStyles = {
    color: loading ? "gray" : "black", // Apply conditional color
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Loading...</div>;
  } else {
    return (
      <>
        <div className="App">
          <h1 className="text-xl font-semibold">SMS Transaction Details</h1>

          <p className="text-xs font-semibold mb-2">
            <span className="text-xl font-semibold">
              {formatNumber(totalRows)}
            </span>{" "}
            Records Found
          </p>
          <div className="text-end pb-2 gap-2">
            <Space direction="vertical" size={14} className="w-52"></Space>
            <RangePicker onChange={onDateChange} /> &nbsp;
            {/* <input type="text" placeholder="Filter" /> */}
            <Select
              style={{
                width: 115,
              }}
              value={filteredItem}
              onChange={onFilterChange}
              options={[
                {
                  value: "batchId",
                  label: "Batch Id",
                },
                {
                  value: "network",
                  label: "Network",
                },
                {
                  value: "senderId",
                  label: "Sender Id",
                },
                {
                  value: "recipient",
                  label: "Recipient",
                },
                {
                  value: "message",
                  label: "Message",
                },
                {
                  value: "smsRoute",
                  label: "SMS Route",
                },
                {
                  value: "status",
                  label: "Status",
                },
              ]}
              allowClear="true"
              placeholder="Filter By"
            />
            {filteredItem === "network" ? (
              <Select
                className="ml-1"
                onChange={onFilterNet}
                options={allNEtwork}
                allowClear="true"
                placeholder="Network"
              />
            ) : (
              <Input
                // value={searchedItem}
                className="w-48 font-poppins ml-1"
                placeholder="Search"
                // allowClear="true"
                // onClear={setClear}
                onChange={handleFilter}
                onKeyDown={handleKeypress}
              />
            )}
            <Button
              type="primary"
              onClick={handleSubmitFilter}
              disabled={searchedItem ? false : true}
              className={
                "rounded-1xbg-indigo-500 text-decoration-none fs-8 hover:scale-105 duration-300 text-white ml-2"
              }
            >
              ok
            </Button>
          </div>
          <DataTable
            columns={columns}
            data={items}
            fixedHeader
            pagination
            highlightOnHover="true"
            paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 50, 100]}
            paginationServer
            paginationTotalRows={totalRows}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePerRowsChange}
            customStyles={{
              rows: {
                style: dataRowStyles,
              },
            }}
          />
        </div>
        {/* <TableTest /> */}
        <ToastContainer autoClose={3000} theme="colored" newestOnTop={true} />
      </>
    );
  }
};

export default SMShistory;
